"use client";

import React, { useEffect, useState } from "react";

import { Label } from "@/components/ui/label";
import { BaseInput, InputProps } from "@/components/ui/base-input";
import { usePlacesWidget } from "react-google-autocomplete";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/dropdown";
import { firebaseConfig } from "@/firebase-client-config";
import { PlusCircle } from "lucide-react";
import { EventLocation } from "@/functions/shared/type/availability/event";
import { SelectPortal } from "@radix-ui/react-select";

type LocationInputValue = {
    address: string;
    location?: { latitude: string; longitude: string };
};

type OtherInputValue = string | number | readonly string[];

interface CommonInputWithLabelProps
    extends Omit<Omit<InputProps, "type">, "value"> {
    formId: string;
    alwaysAlignColumn?: boolean;
    type?:
        | "textarea"
        | "location"
        | "dropdown"
        | "default"
        | "datetime-local"
        | "password"
        | "number";
    alignment?: string;
    note?: string;
    label?: string;
    options?: { value: string; label: string }[];
    onChange?: (event) => void;
    showPlusIcon?: boolean;
    onPlusClick?: (event) => void;
}

interface LocationInputWithLabelProps extends CommonInputWithLabelProps {
    type: "location";
    value?: LocationInputValue;
}

interface OtherInputWithLabelProps extends CommonInputWithLabelProps {
    value?: OtherInputValue;
}

type InputWithLabelProps =
    | OtherInputWithLabelProps
    | LocationInputWithLabelProps;

const getTextArea = ({
    onInputChange,
    inputStyles,
    inputValue,
    formId,
    placeholder,
}) => {
    return (
        <BaseInput
            onChange={onInputChange}
            onPaste={onInputChange}
            className={`${inputStyles}`}
            id={formId}
            placeholder={placeholder}
            value={inputValue as OtherInputValue}
            type="textarea"
        />
    );
};

const useGathaPlacesWidget = (
    onLocationInputChange: (location: {
        target: { value: EventLocation };
    }) => void
) => {
    const { ref } = usePlacesWidget<HTMLInputElement>({
        apiKey: firebaseConfig.apiKey,
        options: {
            types: [
                // "address",
                "sublocality_level_1",
                "sublocality",
                "postal_code",
                "locality",
            ],
            componentRestrictions: { country: "uk" },
            fields: [
                "formatted_address",
                "geometry.location",
                "geometry.viewport",
            ],
        },
        onPlaceSelected: (value) => {
            const address = value?.formatted_address;

            if (!address) {
                return;
            }

            const location = value?.geometry?.location;

            onLocationInputChange({
                target: {
                    value: {
                        address,
                        location: {
                            latitude: location.lat().toString(),
                            longitude: location.lng().toString(),
                        },
                        location_bounds: {
                            northeast: {
                                latitude: value?.geometry?.viewport
                                    .getNorthEast()
                                    .lat()
                                    .toString(),
                                longitude: value?.geometry?.viewport
                                    .getNorthEast()
                                    .lng()
                                    .toString(),
                            },
                            southwest: {
                                latitude: value?.geometry?.viewport
                                    .getSouthWest()
                                    .lat()
                                    .toString(),
                                longitude: value?.geometry?.viewport
                                    .getSouthWest()
                                    .lng()
                                    .toString(),
                            },
                        },
                    },
                },
            });
        },
    });

    return ref;
};

const getLocation = ({
    inputStyles,
    placeholder,
    formId,
    inputValue,
    onLocationInputChange,
}) => {
    return (
        <BaseInput
            onChange={(event) =>
                onLocationInputChange({
                    target: {
                        value: { address: event.target.value },
                    },
                })
            }
            ref={useGathaPlacesWidget(onLocationInputChange)}
            className={`${inputStyles} text-gatha-text-strong`}
            type="text"
            id={formId}
            placeholder={placeholder}
            value={(inputValue as LocationInputValue)?.address ?? ""}
        />
    );
};

const getDropdown = ({ placeholder, options, onInputChange, inputValue }) => {
    return (
        <Select
            value={inputValue}
            onValueChange={(value) => onInputChange({ target: { value } })}
        >
            <SelectTrigger className="bg-white">
                <SelectValue placeholder={placeholder} />
            </SelectTrigger>
            <SelectPortal>
                <SelectContent className="bg-white">
                    <SelectGroup className="max-h-40 ">
                        {/* <SelectLabel>Header</SelectLabel> */}
                        {options.map((option) => (
                            <SelectItem
                                value={option.value}
                                key={`${option.value}_${option.label}`}
                            >
                                {option.label}
                            </SelectItem>
                        ))}
                    </SelectGroup>
                </SelectContent>
            </SelectPortal>
        </Select>
    );
};

const getDefault = ({
    onInputChange,
    inputStyles,
    type,
    formId,
    placeholder,
    inputValue,
}) => {
    return (
        <BaseInput
            onChange={onInputChange}
            className={`${inputStyles}`}
            type={type}
            id={formId}
            placeholder={placeholder}
            value={inputValue as OtherInputValue}
        />
    );
};

const inputTypes = {
    textarea: getTextArea,
    location: getLocation,
    dropdown: getDropdown,
    default: getDefault,
};

const InputWithLabel = React.forwardRef<HTMLInputElement, InputWithLabelProps>(
    (
        {
            className,
            options,
            placeholder,
            formId,
            type,
            alignment,
            value,
            ...props
        },
        _ref
    ) => {
        const [inputValue, setInputValue] = useState(value ? value : "");

        useEffect(() => {
            setInputValue(value);
        }, [value]);

        const inputStyles = "w-full";

        const onInputChange = (event) => {
            setInputValue(event.target.value);

            if (props.onChange) {
                props.onChange(event);
            }
        };

        const onLocationInputChange = (event) => {
            setInputValue(event.target.value);

            if (props.onChange && event.target.value.location) {
                props.onChange(event);
            }
        };

        const inputFunction = inputTypes[type] || inputTypes.default;
        const flexStyle = props.alwaysAlignColumn
            ? ""
            : "sm:flex-row sm:items-center";

        return (
            <div
                className={`${
                    props.label ? "gap-4" : ""
                } ${flexStyle} text-sm ${className} flex flex-col w-full h-full `}
            >
                {props.label ? (
                    <Label className="max-w-sm w-40" htmlFor={formId}>
                        {props.label}
                    </Label>
                ) : null}
                <div className={"flex flex-row w-full h-full"}>
                    {inputFunction({
                        onInputChange,
                        inputStyles,
                        placeholder,
                        inputValue,
                        onLocationInputChange,
                        formId,
                        type,
                        options,
                    })}
                    {props.showPlusIcon ? (
                        <PlusCircle
                            className="flex-none w-4 h-4 m-2 self-center cursor-pointer"
                            onClick={props.onPlusClick}
                        />
                    ) : null}
                </div>
                {props.note ? (
                    <p className="w-full-sm h-full text-gray-500">
                        {props.note}
                    </p>
                ) : null}
            </div>
        );
    }
);

export { InputWithLabel };
