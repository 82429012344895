"use client";

import {
    GoogleAuthProvider,
    createUserWithEmailAndPassword,
    getAuth,
    signInWithEmailAndPassword,
    signInWithPopup,
} from "firebase/auth";
import { getFirebaseApp, saveSecureServerToken } from "@/lib/firebase-client";
import { useEffect, useState } from "react";

import ActionButton from "@/components/ui/action-button";
import Cookies from "js-cookie";
import GoogleIcon from "@/components/icons/google";
import { HttpStatus } from "http-status-ts";
import { InputWithLabel } from "@/components/ui/input-with-label";
import { PageHeader } from "@/components/ui/text/page-header";
import { verboseLog } from "@/lib/utils";

const onLogout = async (event, router) => {
    event.preventDefault();

    const response = await fetch("/api/auth/logout", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (response.status === HttpStatus.OK) {
        Cookies.remove("x-user-id");
        const auth = getAuth(getFirebaseApp());
        await auth.signOut();
        window.location.href = "/";
    }
};

const handleCredentialResponse = async (credentialResponse, router) => {
    const response = await saveSecureServerToken(credentialResponse);

    verboseLog("server response", response);

    const redirectPath = Cookies.get("x-auth-redirect");

    verboseLog(`redirectPath: ${redirectPath}`);

    if (redirectPath) {
        Cookies.remove("x-auth-redirect");
        window.location.href = redirectPath;
    } else {
        window.location.href = "/";
    }
};

const getLoginForm = ({
    handleSubmit,
    router,
    isRegister,
    setEmail,
    email,
    password,
    setPassword,
    handleGoogleSignIn,
    setIsRegister,
}) => {
    return (
        <div className="flex lg:flex-row flex-col items-center w-full h-full gap-8">
            <div className="flex flex-col gap-4 w-full lg:max-w-lg">
                <div className="flex flex-row gap-6 w-full">
                    <div className="flex flex-col gap-1 w-80 h-20">
                        <div className="font-semibold text-sm">Your Email</div>
                        <div className="text-xs">
                            {isRegister
                                ? "We use this to identify you and send you updates your have subscribed to."
                                : "The one you used to register with"}
                        </div>
                    </div>
                    <InputWithLabel
                        onChange={(event) => setEmail(event.target.value)}
                        formId="email"
                        type="default"
                        placeholder="name@domain.com"
                        value={email}
                    />
                </div>
                <div className="flex flex-row gap-6">
                    <div className="flex flex-col gap-1 w-80 h-20">
                        <div className="font-semibold text-sm">
                            Your Password
                        </div>
                        <div className="text-xs">
                            {isRegister
                                ? "Try write a sentence to make it secure and easy to remember 'MyFavouriteWebsite', for example."
                                : "Hopefully you used a sentence like 'MyFavouriteWebsite'"}
                        </div>
                    </div>
                    <InputWithLabel
                        onChange={(event) => setPassword(event.target.value)}
                        typeof="password"
                        formId="password"
                        type="password"
                        placeholder="..."
                        value={password}
                    />
                </div>
                <div className="flex flex-col justify-between gap-2 items-end">
                    <ActionButton
                        className="text-xs underline"
                        textOnly={true}
                        style="link"
                        label={
                            isRegister
                                ? "Already have an account? Click here to Sign In"
                                : "Don't have an account? Click here to Register"
                        }
                        onClick={() => setIsRegister(!isRegister)}
                    />
                    <ActionButton
                        className="max-w-md w-36"
                        label={isRegister ? "Register" : "Sign In"}
                        onClick={(event) => handleSubmit(event, router)}
                    />
                </div>
            </div>
            <div className="w-0.5 bg-gray-200 hidden lg:block mt-2 mb-2 h-full" />
            <div className="flex flex-col gap-5 flex-0 w-full items-center lg:items-center justify-end h-full">
                <div className="font-semibold text-sm">OR</div>
                <div className="text-xs">
                    Use your Google account to sign in or register. We only use
                    your email address to create an identify and don't have
                    access to any other details.
                </div>
                <ActionButton
                    className=""
                    label={
                        isRegister
                            ? "Register with Google"
                            : "Sign In with Google"
                    }
                    icon={<GoogleIcon className="w-6 h-6" />}
                    onClick={(event) => handleGoogleSignIn(event, router)}
                />
            </div>
        </div>
    );
};

const getLogoutForm = ({ router }) => {
    return (
        <div className="flex items-center w-full lg:max-w-md justify-center">
            <div className="flex flex-col gap-5 w-full items-start justify-start h-full">
                <div className="">
                    It looks like you're already logged in. Would you like to
                    sign out instead?
                </div>
                <ActionButton
                    className=""
                    label="Sign Out"
                    onClick={(event) => onLogout(event, router)}
                />
            </div>
        </div>
    );
};

const AuthClientPage = ({ router, isRegistration, jwtIsValid }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isRegister, setIsRegister] = useState(isRegistration);

    useEffect(() => {
        setIsRegister(isRegistration);
    }, [isRegistration]);

    const handleSubmit = async (event, router) => {
        event.preventDefault();
        try {
            let result;
            if (isRegister) {
                const auth = getAuth(getFirebaseApp());
                result = await createUserWithEmailAndPassword(
                    auth,
                    email,
                    password
                );
            } else {
                const auth = getAuth(getFirebaseApp());
                result = await signInWithEmailAndPassword(
                    auth,
                    email,
                    password
                );
            }

            const token = await result.user.getIdToken();
            await handleCredentialResponse(token, router);
            verboseLog("Token:", token);
        } catch (error) {
            verboseLog("Error:", error.message);
            throw error;
        }
    };

    const handleGoogleSignIn = async (event, router) => {
        event.preventDefault();

        verboseLog("handleGoogleSignIn");
        try {
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(
                getAuth(getFirebaseApp()),
                provider
            );
            const token = await result.user.getIdToken();

            await handleCredentialResponse(token, router);
        } catch (error) {
            verboseLog("Error:", error.message);
            throw error;
        }
    };

    const loginProps = {
        handleSubmit,
        router,
        isRegister,
        setEmail,
        email,
        password,
        setPassword,
        setIsRegister,
        handleGoogleSignIn,
    };

    return (
        <div
            onSubmit={(event) => handleSubmit(event, router)}
            className="flex gap-y-10 flex-col w-full items-start max-w-5xl p-10"
        >
            <div className="flex flex-col gap-8">
                <PageHeader
                    className="w-full flex flex-row gap-"
                    label={
                        isRegister
                            ? "Register"
                            : jwtIsValid
                            ? "Sign Out"
                            : "Sign In"
                    }
                />
                {jwtIsValid ? null : (
                    <div>
                        Sign in to access your plans and create new ones. We
                        won't ever spam you...
                    </div>
                )}
            </div>
            <div className="flex flex-col lg:flex-row gap-10 gap-x-4 flex-wrap w-full">
                {jwtIsValid
                    ? getLogoutForm({ router })
                    : getLoginForm(loginProps)}
            </div>
        </div>
    );
};

export default AuthClientPage;
