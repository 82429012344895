import { verboseLog } from "./lib/utils";

type FirebaseConfig = {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
};

const environmentConfigMap: Map<string, FirebaseConfig> = new Map([
    [
        "development",
        {
            apiKey: "AIzaSyATOy61ZRxEtT10TXcoNk7De1S5D_NrO7Y",
            authDomain: "gatha-preproduction.firebaseapp.com",
            projectId: "gatha-preproduction",
            storageBucket: "gatha-preproduction.appspot.com",
            messagingSenderId: "506804839429",
            appId: "1:506804839429:web:dde85ea485b8a7d556686c",
            measurementId: "G-RD9SERH8XD",
        },
    ],
    [
        "preproduction",
        {
            apiKey: "AIzaSyATOy61ZRxEtT10TXcoNk7De1S5D_NrO7Y",
            authDomain: "gatha-preproduction.firebaseapp.com",
            projectId: "gatha-preproduction",
            storageBucket: "gatha-preproduction.appspot.com",
            messagingSenderId: "506804839429",
            appId: "1:506804839429:web:dde85ea485b8a7d556686c",
            measurementId: "G-RD9SERH8XD",
        },
    ],
    [
        "production",
        {
            apiKey: "AIzaSyATOy61ZRxEtT10TXcoNk7De1S5D_NrO7Y",
            authDomain: "gatha-preproduction.firebaseapp.com",
            projectId: "gatha-preproduction",
            storageBucket: "gatha-preproduction.appspot.com",
            messagingSenderId: "506804839429",
            appId: "1:506804839429:web:dde85ea485b8a7d556686c",
            measurementId: "G-RD9SERH8XD",
        },
    ],
]);

const exportedConfig = environmentConfigMap.get(process.env.NODE_ENV);

verboseLog(
    `getFirebaseConfigForEnvironment: environment: ${
        process.env.NODE_ENV
    } config: ${JSON.stringify(exportedConfig)}`
);

const firebaseConfig = exportedConfig;

export { firebaseConfig };
