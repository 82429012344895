import * as React from "react";

import { cn } from "@/lib/utils";

export interface InputProps
    extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    type?: "text" | "textarea" | "location";
}

const commonClasses = `
    rounded-xl flex w-full rounded-md 
    border border-gray-300
    py-2 px-3
    placeholder:text-gray-400 
    disabled:cursor-not-allowed disabled:opacity-50 
    focus:border-gatha-dark-purple focus:outline-none`;

const textAreaClasses = cn(commonClasses, "h-full");
const inputClasses = cn(commonClasses, "h-10");

const BaseInput = React.forwardRef<
    HTMLInputElement | HTMLTextAreaElement,
    InputProps
>(({ className, type, ...props }, ref) => {
    return type === "textarea" ? (
        <textarea
            className={cn(textAreaClasses, className)}
            ref={ref as React.Ref<HTMLTextAreaElement>}
            {...props}
        />
    ) : (
        <input
            type={type}
            className={cn(inputClasses, className)}
            ref={ref as React.Ref<HTMLInputElement>}
            {...props}
        />
    );
});
BaseInput.displayName = "Input";

export { BaseInput };
