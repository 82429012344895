import { getApps, initializeApp } from "firebase/app";
import { getAuth, User } from "firebase/auth";
import { HttpStatus } from "http-status-ts";
import { verboseLog } from "./utils";
import { firebaseConfig } from "@/firebase-client-config";

const saveSecureServerToken = async (firebaseIdToken) => {
    try {
        const response = await fetch("/api/auth", {
            credentials: "include",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${firebaseIdToken}`,
            },
            body: JSON.stringify({ idToken: firebaseIdToken }),
        });

        const firebaseUser = getAuth(getFirebaseApp()).currentUser;
        verboseLog(`firebaseUser: ${firebaseUser}`);

        if (response.status === HttpStatus.OK) {
            return response.json();
        } else {
            return { errorCode: response.status };
        }
    } catch (error) {
        return { errorCode: HttpStatus.INTERNAL_SERVER_ERROR };
    }
};

const authStateChangedAsync: () => Promise<User | null> = async () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = getAuth(getFirebaseApp()).onAuthStateChanged(
            (user) => {
                unsubscribe(); // Detach the listener once we get the user object
                resolve(user);
            },
            (error) => {
                unsubscribe(); // Detach the listener in case of an error
                reject(error);
            }
        );
    });
};

const getFirebaseApp = () => {
    // Initialize Firebase but only if it hasn't been initialized already
    if (getApps().length) {
        return getApps()[0];
    } else {
        const app = initializeApp(firebaseConfig);
        return app;
    }
};

export { getFirebaseApp, authStateChangedAsync, saveSecureServerToken };
