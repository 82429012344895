"use client";

import { useRouter } from "next/navigation";
import AuthClientPage from "./auth-client-page";

interface AuthPageViewProps {
    jwtIsValid: boolean;
    isRegistration: boolean;
    isInStorybook?: boolean;
}

const AuthPageView: React.FC<AuthPageViewProps> = ({
    jwtIsValid,
    isRegistration,
    isInStorybook = false,
}) => {
    return (
        <div className="flex justify-center w-full">
            <div
                data-target-id="scroll-shadow"
                data-trigger-margin="-var(--header-open-height)"
            ></div>
            <AuthClientPage
                jwtIsValid={jwtIsValid}
                router={
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    isInStorybook ? { push: () => {} } : useRouter()
                }
                isRegistration={isRegistration}
            />
        </div>
    );
};

export { AuthPageView };
