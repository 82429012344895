import { cn } from "@/lib/utils";

const PageHeader = ({
    label,
    colourClassesOverride = undefined,
    className = "",
}) => {
    const colourClasses = colourClassesOverride
        ? colourClassesOverride
        : ["text-gatha-text-strong", "text-gatha-primary-purple"];

    return (
        <h2
            // style={{ fontFamily: "var(--space-grotesk)" }}
            className={cn(
                "text-6xl tracking-tighter font-black leading-none",
                className
            )}
        >
            {label?.split(" ").map((word, index) => {
                return (
                    <div
                        className={cn(
                            "flex w-fit",
                            colourClasses[index % colourClasses.length]
                        )}
                        key={word}
                    >
                        {word}
                    </div>
                );
            })}
        </h2>
    );
};

export { PageHeader };
