"use client";

import React, { MouseEventHandler } from "react";

import { cn } from "@/lib/utils";

interface IconProps {
    style?: React.CSSProperties;
    // Add any other properties you want to use for the icon
}

interface ActionButtonProps {
    label: string;
    style?:
        | "green"
        | "purple"
        | "green-inverse"
        | "purple-inverse"
        | "link"
        | "yellow";
    onClick?: MouseEventHandler | undefined;
    onMouseOver?: MouseEventHandler | undefined;
    className?: string;
    disabled?: boolean;
    textOnly?: boolean;
    icon?: JSX.Element & { props: IconProps };
}

const styles: Map<string, { classes: string; iconColour: string }> = new Map([
    [
        "green",
        {
            classes: "bg-gatha-primary-green text-white",
            iconColour: "var(--text-white)",
        },
    ],
    [
        "purple",
        {
            classes: "bg-gatha-primary-purple text-white",
            iconColour: "var(--text-white)",
        },
    ],
    [
        "green-inverse",
        {
            classes: "bg-white text-gatha-primary-green",
            iconColour: "var(--gatha-primary-green)",
        },
    ],
    [
        "purple-inverse",
        {
            classes: "bg-white text-gatha-primary-purple",
            iconColour: "var(--gatha-primary-purple)",
        },
    ],
    [
        "link",
        {
            classes: "text-gatha-text-strong",
            iconColour: "var(--gatha-text-strong)",
        },
    ],
    [
        "yellow",
        {
            classes: "bg-gatha-secondary-yellow text-gatha-dark-purple",
            iconColour: "var(--text-gatha-dark-purple)",
        },
    ],
]);

const ActionButton: React.FC<ActionButtonProps> = ({
    label,
    icon,
    onClick,
    onMouseOver,
    className,
    disabled,
    style = "green",
    textOnly = false,
}) => {
    const styleProps = styles.get(style);
    const textClass = textOnly
        ? "p-1"
        : "bg-gatha-primary-green p-2 pr-5 pl-5 ";
    const disabledClasses = disabled
        ? "bg-gray-500 opacity-60"
        : "cursor-pointer";

    const defaultClasses =
        "flex flex-row justify-center w-fit text-gatha-text-strong gap-2 rounded-full  bg-gatha-orange-900 items-center";

    const iconStyle = icon?.props?.style ? icon.props.style : {};
    iconStyle.color = styleProps.iconColour;

    return (
        <button
            className={cn(
                defaultClasses,
                textClass,
                disabledClasses,
                styleProps.classes,
                className
            )}
            onMouseOver={(event) => {
                event.preventDefault();
                if (onMouseOver) onMouseOver(event);
            }}
            onClick={(event) => {
                event.preventDefault();
                if (onClick) onClick(event);
            }}
        >
            <h3 className="pointer-events-none select-none ">{label}</h3>
            {icon ? React.cloneElement(icon, { style: iconStyle }) : null}
        </button>
    );
};

export default ActionButton;
